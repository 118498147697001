<template>
  <div>
    <newBanner></newBanner>
    <newSpell></newSpell>
    <newInfo></newInfo>
    <myService></myService>
    <newOcean></newOcean>
    <newContact></newContact>
<!--    <banner></banner>-->
<!--    <ourServices></ourServices>-->
<!--    <oceanServices></oceanServices>-->
<!--    <contactOur></contactOur>-->
  </div>
</template>

<script>
import newBanner from "~ymp/components/home/newBanner"
import newSpell from "~ymp/components/home/newSpell"
import newInfo from "~ymp/components/home/newInfo"
import myService from "~ymp/components/home/myService"
import newOcean from "~ymp/components/home/newOcean"
import newContact from "~ymp/components/home/newContact"
// import banner from "~ymp/components/home/banner"
// import ourServices from "~ymp/components/home/ourServices"
// import oceanServices from "~ymp/components/home/oceanServices"
// import contactOur from "~ymp/components/home/contactOur"
export default {
  components: {
    newBanner,
    newSpell,
    newInfo,
    myService,
    newOcean,
    newContact
    // banner,
    // ourServices,
    // oceanServices,
    // contactOur
  },
  mounted(){
    this.$log({
      type: 2,
      topic: "ymp.home",
      id: "ymp.home",
      // content: JSON.stringify({
      //   agenda_id: item.id,
      // }),
    });
  },
  methods:{
    
  }

}
</script>

<style>
</style>