<template>
  <div>
    <el-select
        v-model="port_id"
        filterable
        :size="size"
        :placeholder="placeTitle"
        remote
        :remote-method="remoteMethod"
        :clearable="clearable"
        :disabled="disabled"
        :loading="loading"
        @focus="focus"
        :style="{ width: width }"
    >
      <el-option
          v-for="item in options"
          :key="item.id"
          :label="item.name_en"
          :value="item.id"
      >
        <div>[{{ item.code }}] {{ item.name_en }}</div>
      </el-option>
    </el-select>
  </div>
</template>

<script>
import { portList } from "~yst/utils/enumValue";
export default {
  name: "portSelect",
  props: {
    width: {
      type: String,
      default: "",
    },
    placeTitle: {
      type: String,
      default: "",
    },
    value: {
      type: String | Number,
      default: null,
    },
    size: {
      type: String,
      default: "small",
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      options: [],
      port_id: null,
      loading: false
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.port_id = this.value;
    });
  },
  watch: {
    port_id(nv) {
      this.$emit("input", nv);
    },
    value(nv) {
      this.port_id = nv;
    },
  },
  methods: {
    async focus () {
      let data = await this.$store.dispatch('API_bookingSpace/getDestination');
      if (data.success) {
        this.options = data.data
        this.options.forEach(item => {
          item.id = item.port_id
        })
      }
      this.$emit('focus',data);
    },
    async remoteMethod(queryString) {
      this.loading = true
      let arr = await this.queryCompany(queryString)
      this.options = arr
    },
    async queryCompany(queryString) {
      try {
        let parmas = {};
        parmas.keyword = queryString
        parmas.nopage = 1
        parmas.limit = 20
        parmas.start = 0
        let data = await this.$store.dispatch('API_bookingSpace/getPortList', parmas);
        this.loading = false
        if (data.success) {
          return data.data
        }
      } catch {
      }
    },
  },
};
</script>

<style scoped>
</style>
