<template>
  <div id="container"></div>
</template>
<script>
export default {
  props: {//注:经纬度的坐标一定要是 WGS-84格式的,转换网址:https://tool.lu/coordinate/
    longitude: {
      //经度
      type: Number,
      default: 116.404,
    },
    dimension: {
      //纬度
      type: Number,
      default: 39.915,
    },
  },
  data() {
    return {
      coordinate: "",
    };
  },
  methods: {
    getMap() {
      var map = new BMapGL.Map("container");
      // 创建地图实例
      // var point = new BMapGL.Point(121.494386, 31.295739);
      var point = new BMapGL.Point(this.longitude, this.dimension);
      // 创建点坐标
      map.centerAndZoom(point, 15);
      var opts = {
        width: 280,
        height: 0
      };
      var infoWindow = new BMapGL.InfoWindow('上海市杨浦区荆州路168号安联大厦509室', opts);
      map.openInfoWindow(infoWindow, point);
      map.enableScrollWheelZoom();
      // 初始化地图，设置中心点坐标和地图级别
      var point =  new BMapGL.Point(this.longitude, this.dimension);
      var marker = new BMapGL.Marker(point); // 创建标注
      map.addOverlay(marker);
      var scaleCtrl = new BMapGL.ScaleControl(); // 添加比例尺控件
      map.addControl(scaleCtrl);
      var zoomCtrl = new BMapGL.ZoomControl(); // 添加比例尺控件
      map.addControl(zoomCtrl);
    },
  },
  mounted() {
    this.getMap();
  },
};
</script>
<style lang="less" scoped>
#container {
  height: 100%;
}
/deep/.BMap_bubble_top div{
  display: none!important;
}
</style>