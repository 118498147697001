import { render, staticRenderFns } from "./newOcean.vue?vue&type=template&id=1a12dc93&scoped=true"
import script from "./newOcean.vue?vue&type=script&lang=js"
export * from "./newOcean.vue?vue&type=script&lang=js"
import style0 from "./newOcean.vue?vue&type=style&index=0&id=1a12dc93&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../opt/deploy_node_modules/ailaworld/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a12dc93",
  null
  
)

export default component.exports