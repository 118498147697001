import { render, staticRenderFns } from "./newInfo.vue?vue&type=template&id=48d7f344&scoped=true"
import script from "./newInfo.vue?vue&type=script&lang=js"
export * from "./newInfo.vue?vue&type=script&lang=js"
import style0 from "./newInfo.vue?vue&type=style&index=0&id=48d7f344&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../opt/deploy_node_modules/ailaworld/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48d7f344",
  null
  
)

export default component.exports