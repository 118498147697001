<template>
  <div class="service-maxbox">
    <div class="title">
      <div class="title-max">我们的服务 <span class="title-min">Our Services</span></div>
    </div>
    <div class="img">
     <img src="https://ym-attached-file.oss-cn-shanghai.aliyuncs.com/pcProject/ourServices.png">
   </div>
  </div>
</template>

<script>
export default {
  name: "myService",
  data() {
    return{

    }
  },
  mounted() {
  },
  methods:{

  }
}
</script>

<style lang="less" scoped>
.title{
  width: 1300px;
  margin: 0 auto;
  .title-max {
    font-size: 26px;
    color: #91BE42;
  }
  .title-min {
    font-size: 20px;
    color: #DFDFDF;
  }
}
.img{
  margin-top: 50px;
  text-align: center;
}
</style>