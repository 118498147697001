<template>
  <div class="spell-maxbox" style="z-index:2">
    <div class="min-box">
      <el-card class="min-weiBox" :body-style="{ padding: '0' }">
        <div class="minbox-top">
          <div class="tab" :class="type == 0 ? 'active-tab' : ''" @click="handleTab(0)">
            危拼运费查询
          </div>
          <div class="tab" :class="type == 1 ? 'active-tab' : ''" @click="handleTab(1)">
            整箱运费查询
          </div>
          <!-- <div
            class="tab"
            :class="type == 2 ? 'active-tab' : ''"
            @click="handleTab(2)"
          >
            订单快捷查询
          </div> -->
        </div>
        <div class="minbox-bottom" v-show="type == 0">
          <div class="item" style="padding-left: 40px">
            <span class="label">起运港<span class="split"></span></span>
            <portSelect class="select" :value="from_port" placeTitle="请输入起运港港口中文或英文名称" clearable :width="'243px'"
              @input="from_port_input" />
          </div>
          <div class="item item-border">
            <span class="label">目的港<span class="split"></span></span>
            <portSelectNew class="select" :value="dest_port_id" placeTitle="请输入目的港港口中文或英文名称" clearable :width="'243px'"
              @input="dest_port_input" />
          </div>
          <div class="item">
            <span class="label">UNNO<span class="split"></span></span>
            <el-input :readonly="true" placeholder="危拼查询不区分UNNO分类" style="width: 243px" />
          </div>
          <div class="item" @click="toSearch">
            <el-button icon="el-icon-search" class="btn-icon"></el-button>
          </div>
        </div>
        <div class="minbox-bottom" v-show="type == 1">
          <div class="item" style="padding-left: 40px">
            <span class="label">起运港<span class="split"></span></span>
            <portSelect class="select" :value="from_port" placeTitle="请输入起运港港口中文或英文名称" clearable :width="'243px'"
              @input="from_port_input" />
          </div>
          <div class="item item-border">
            <span class="label">目的港<span class="split"></span></span>
            <portSelectNew class="select" :value="dest_port" placeTitle="请输入目的港港口中文或英文名称" clearable :width="'243px'"
              @input="dest_port_input" />
          </div>
          <div class="item">
            <span class="label">UNNO<span class="split"></span></span>
            <el-input v-model="unno" placeholder="请输入危险货物的UNNO" style="width: 243px" />
          </div>
          <div class="item" @click="toSearch">
            <el-button icon="el-icon-search" class="btn-icon"></el-button>
          </div>
        </div>
        <!-- <div class="minbox-bottom oqq" v-show="type == 2">
          <div class="flex_box">
            <div style="width: 120px">订单号/提单号 <span class="split"></span></div>
            <div class="search_input">
              <el-input style=" width: 970px; height: 60px;  display: flex; align-items: center;" v-model="orderString"
                :clearable="true" placeholder="请输入完整编号" />
            </div>
            <div class="item" @click="orderQueryCheckLogin">
              <el-button icon="el-icon-search" class="btn-icon"></el-button>
            </div>
          </div>
          <div class="tips">注：支持订单号和提单号查询</div>
        </div> -->
        <div class="booking_btn cursor" v-if="$store.state.baseStore.userInfo.user_type == 2" @click="customerBooking">
          <img src="https://ym-attached-file.oss-cn-shanghai.aliyuncs.com/pcProject/booking_btn.png" alt="">
        </div>
      </el-card>
      <div class="twoSearchBox">
        <div class="searchOrder">
          <img src="https://ym-attached-file.oss-cn-shanghai.aliyuncs.com/pcProject/dd.png" alt="" srcset="">
          <p class="searchTitle">
            订单快捷查询 <span class="pageTitleDesc">订单号/提单号</span>
          </p>
          <div class="searchBox">
            <el-input style=" width: 460px; height: 60px;  display: flex; align-items: center;" v-model="orderString"
              :clearable="true" placeholder="请输入完整编号" />

            <el-button class="" type="success" @click="orderQueryCheckLogin">查询</el-button>
          </div>
        </div>
        <div class="searchCar">
          <img src="https://ym-attached-file.oss-cn-shanghai.aliyuncs.com/pcProject/hc.png" alt="" srcset="">
          <p class="searchTitle">
            车辆入库查询 <span class="pageTitleDesc">提单号</span>
          </p>
          <div class="searchBox">
            <el-input style=" width: 460px; height: 60px;  display: flex; align-items: center;" v-model="carOrder"
              :clearable="true" placeholder="请输入完整提单号" />

            <el-button class="" type="success" @click="searchCardInfo">查询</el-button>
          </div>
        </div>
      </div>
      <div class="title">
        <div class="title-max">危拼热门航线 <span class="title-min">Dangerous Hot Routes</span></div>
      </div>
      <div class="loop">
        <el-carousel :interval="3000" arrow="never">
          <el-carousel-item v-for="(item, index) in dataList" :key="index">
            <div class="loop-maxbox">
              <div class="loop-minbox" v-for="(item1, index1) in dataList[index]" :key="index1">
                <div class="loop-min-top">
                  <div class="loop-box loop-one">
                    <div>{{ item1.from_port }}</div>
                    <div style="padding:0 10px; position: relative;">
                      <img src="https://ym-attached-file.oss-cn-shanghai.aliyuncs.com/pcProject/arrow-right.png" />
                      <div class="loop-date">{{ item1.days }}天</div>
                    </div>
                    <div class="loop-space" :title="item1.dest_port" @click="handleMore(item1)">
                      {{ item1.dest_port }}
                    </div>
                  </div>
                  <div class="loop-box loop-tow">
                    <div>起运港：{{ item1.from_port_zh }}</div>
                    <div>目的港：{{ item1.dest_port_zh }}</div>
                  </div>
                  <div class="loop-box loop-serren">
                    <div class="dataLabel5 color000" :title="item.shipping_date">
                      出发时间：{{ item1.shipping_date | weekDaysFormat }}
                    </div>
                    <img class="loop-shipping"
                      src="https://ym-attached-file.oss-cn-shanghai.aliyuncs.com/pcProject/shipping_icon.png" alt="">

                  </div>
                </div>
                <div class="loop-min-bottom">
                  <div>
                    <span style="color: #0c263f">USD </span>
                    <span style="color: #ff7727" :class="USER_INFO ? '' : 'mohu'">
                      {{
                        item1.price_lcl_dangerous < 0 ? "(" + Math.abs(item1.price_lcl_dangerous).toFixed() + ")" :
                        item1.price_lcl_dangerous.toFixed() }}</span>
                  </div>
                  <div style="font-size: 12px; color: #37ab0e; cursor: pointer" @click="handleMore(item1)">
                    更多 >
                  </div>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>

    </div>

  </div>
</template>

<script>
import portSelectNew from "~ymp/components/common/portSelectNew";
import portSelect from "~ymp/components/common/portSelect";
import { portFormat } from "~ymp/plugins/filters";
export default {
  name: "newSpell",
  components: {
    portSelect,
    portSelectNew,
  },
  data() {
    return {
      type: 0,
      from_port_id: 4179,
      from_port: "SHANGHAI",
      dest_port_id: "",
      dest_port: "",
      unno: "",
      orderString: "",
      dataList: [],
      carOrder: ''
    };
  },
  mounted() {
    this.getFreight();
  },
  methods: {
    //危拼热门航线
    async getFreight() {
      let data = await this.$store.dispatch("API_freight/getFreight");
      for (var i = 0; i < data.data.length; i += 3) {
        this.dataList.push(data.data.slice(i, i + 3));
      }
      // this.dataList = data.data;
      // console.log(data);
    },
    //航线详情
    handleMore(item) {
      if (!this.IS_LOGIN) {
        return this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN", {
          waitTodo: () => {
            this.handleMore(item);
          },
        });
      }
      this.$log({
        type: 1,
        topic: "ymp.home",
        id: "ymp.home.hotLine",
        content: JSON.stringify({
          pol: item.from_port_id || '',
          POD: item.dest_port_id || ''
        }),
      });
      this.$router.push({
        query: {
          id: item.id,
          shipping_line_id: item.shipping_line_id,
          min: item.mini_cargo
        },
        path: "/freightSearchDetailsCFS",
      });
    },
    handleTab(index) {
      this.type = index;
    },
    from_port_input(val) {
      if (val === "") val = null;
      let num = Number(val);
      if (!isNaN(num) || val === "") {
        this.from_port_id = val;
      }
    },
    dest_port_input(val) {
      if (val === "") val = null;
      let num = Number(val);
      if (!isNaN(num) || val === "") {
        this.dest_port_id = val;
      }
    },
    orderQueryCheckLogin() {
      if (!this.IS_LOGIN) {
        return this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN", {
          waitTodo: () => {
            this.orderQuery();
          },
        });
      } else {
        this.orderQuery();
      }
    },
    searchCardInfo() {
      if (!this.IS_LOGIN) {
        return this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN", {
          waitTodo: () => {
            this.pathToCarSearch()
          },
        });
      } else {
        this.pathToCarSearch()
      }
    },
    pathToCarSearch() {
      if(!this.carOrder){
        this.$message.warning('请输入完整提单号')
        return;
      }
      this.$router.push({
        path: "/carSearchResult",
        query: {
          no: this.carOrder,
        }
      });
    },
    // 订单查询
    async orderQuery() {
      if (this.orderString) {
        this.$log({
          type: 1,
          topic: "ymp.home",
          id: "ymp.home.order",
          content: JSON.stringify({
            order: this.orderString
          }),
        });
        let res = await this.$store.dispatch("API_freight/getAllOrderInfo", {
          job_no: this.orderString,
          user_id: this.USER_ID
        });
        if (res.success && res.data.length) {
          this.$router.push({
            path: "/console/myEntrust/detailsOrdered",
            query: {
              id: res.data[0].order_id,
              order_id: res.data[0].order_id,
              orderString: this.orderString,
            }
          });
        } else {
          this.$confirm(
            "未找到符合条件的订单，请输入正确的订单号/提单号。",
            "提示",
            {
              confirmButtonText: "我知道了",
              showCancelButton: true,
              type: "warning",
            }
          )
            .then(() => {
            })
            .catch(() => {
              return;
            });
        }
      } else {
        this.$message.warning('请输入完整编号')
        return;
      }
    },
    customerBooking() {
      this.$router.push({
        query: {
          info: 'custom'
        },
        path: "/console/clients/booking",
      })
    },
    // 危拼运费查询
    async toSearch() {
      if (this.type == 0) {
        setTimeout(() => {
          this.$log({
            type: 1,
            topic: "ymp.home",
            id: "ymp.home.lcl",
            content: JSON.stringify({
              pol: this.from_port_id || '',
              POD: this.dest_port_id || ''
            }),
          });
        }, 1000)
        this.$router.push({
          query: {
            from_port_id: this.from_port_id,
            from_port: portFormat(this.from_port_id),
            dest_port_id: this.dest_port_id,
            dest_port: portFormat(this.dest_port_id),
            activeName: "second",
          },
          path: "/freightSearch",
        });
      } else {
        setTimeout(() => {
          this.$log({
            type: 1,
            topic: "ymp.home",
            id: "ymp.home.fcl",
            content: JSON.stringify({
              pol: this.from_port_id || '',
              POD: this.dest_port_id || '',
              unno: this.unno || ''
            }),
          });
        }, 1000)
        if (this.unno) {
          let res = await this.$store.dispatch("API_freight/check_unno", {
            unno: this.unno,
          });
          if (res.success) {
            this.$router.push({
              query: {
                from_port_id: this.from_port_id,
                from_port: portFormat(this.from_port_id),
                dest_port_id: this.dest_port_id,
                dest_port: portFormat(this.dest_port_id),
                unno: this.unno,
                activeName: "first",
              },
              path: "/freightSearch",
            });
          } else {
            this.$confirm(
              "您输入的UNNO有误，如果继续查询，将展示普通货物价格。",
              "提示",
              {
                confirmButtonText: "查看普货运价",
                cancelButtonText: "更改UNNO",
                type: "warning",
              }
            )
              .then(() => {
                this.unno = "";
                this.$router.push({
                  query: {
                    from_port_id: this.from_port_id,
                    from_port: portFormat(this.from_port_id),
                    dest_port_id: this.dest_port_id,
                    dest_port: portFormat(this.dest_port_id),
                    activeName: "first",
                  },
                  path: "/freightSearch",
                });
              })
              .catch(() => {
                return;
              });
          }
        } else {
          this.$router.push({
            query: {
              from_port_id: this.from_port_id,
              from_port: portFormat(this.from_port_id),
              dest_port_id: this.dest_port_id,
              dest_port: portFormat(this.dest_port_id),
              activeName: "first",
            },
            path: "/freightSearch",
          });
        }
      }
    },
  },
};
</script>

<style scoped lang="less">
.twoSearchBox {
  width: 1300px;
  margin: 25px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;

  .searchOrder {
    img {
      width: 84px;
      height: 97px;
    }
  }

  .searchCar {
    img {
      width: 127px;
      height: 70px;
    }
  }

  &>div {
    width: 639px;
    height: 146px;
    background: rgba(145, 190, 66, .06);
    box-shadow: 0px 3px 7px 1px rgba(220, 220, 220, 0.35);
    border-radius: 5px;
    position: relative;
    padding: 0 25px;

    .searchTitle {
      font-size: 21px;
      color: #91BE42;
      font-weight: 600;
      margin: 20px 0;

      span {
        color: #333;
        font-size: 14px;
        margin-left: 10px;
      }
    }

    .searchBox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      align-items: center;

      button {
        width: 122px;
        height: 40px;
        background: #97c641;
        border: none;
      }

      /deep/ .el-input__inner {
        border: 1px solid #91BE42;
      }
    }

    &>img {
      position: absolute;
      right: 62px;
      top: 7px;
      z-index: -1;
    }
  }
}

/deep/.el-carousel__button {
  background-color: #97c641;
}

/deep/ .el-carousel__container {
  height: 250px;
}

/deep/ .el-input__inner {
  border: none;
  outline: none;
}

/deep/ .el-icon-search {
  color: #91BE42;
  font-size: 20px;
}

.el-carousel__item {
  height: 236px;
}

.spell-maxbox {
  width: 100%;
  // height: 470px;
  position: relative;

  .min-box {
    display: flex;
    flex-flow: column;
    align-items: center;
  }

  .min-weiBox {
    width: 1300px;
    min-height: 190px;
    margin-top: -128px;
    box-sizing: border-box;
    position: relative;

    .minbox-top {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      overflow: hidden;

      .tab {
        text-align: center;
        color: #B7B7B7;
        font-size: 21px;
        font-family: Helvetica;
        padding: 27px 69px;
        cursor: pointer;
      }
    }

    .minbox-bottom {
      width: 1236px;
      height: 70px;
      border: 1px solid #91be42;
      margin: 0 auto;
      margin-top: 12px;
      padding: 5px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .item-border {
        border-right: 1px solid #d8d8d8;
      }

      .item {
        display: flex;
        align-items: center;

        .img {
          margin-right: 40px;
        }

        .lable {
          color: #0c263f;
          font-size: 16px;
        }

        .btn-icon {
          width: 82px;
          height: 62px;
          border: none;

          &:hover {
            background: none;
          }
        }
      }
    }

    .booking_btn {
      position: absolute;
      right: 27px;
      top: 20px;

      img {
        width: 143px;
        height: 46px;
      }
    }
  }

  .oqq {
    .flex_box {
      display: flex;
      align-items: center;
      padding-left: 40px;

      .search_input {
        width: 982px;
      }
    }

    .tips {
      font-size: 12px;
      color: #999;
      margin-top: 10px;
      margin-left: 150px;
    }

    .btn-icon {
      width: 88px;
      height: 70px;
    }
  }

  .title {
    width: 1300px;
    margin-top: 48px;

    .title-max {
      font-size: 26px;
      color: #91BE42;
    }

    .title-min {
      font-size: 20px;
      color: #DFDFDF;
    }
  }

  .loop {
    width: 1300px;
    height: 260px;
    margin-top: 32px;

    .loop-maxbox {
      width: 100%;
      display: flex;
      align-items: center;

      //justify-content: space-between;
      .loop-minbox {
        width: 410px;
        height: 220px;
        background: #FFFFFF;
        box-shadow: 0px 3px 7px 1px rgba(220, 220, 220, 0.3500);
        margin-top: 5px;
        border-radius: 5px;
        margin-right: 33px;

        &:nth-child(3n) {
          margin-right: 5px;
        }

        .loop-min-top {
          height: 170px;
          padding: 30px 24px;
          box-sizing: border-box;
          position: relative;

          .loop-box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            font-weight: 400;
            color: #0c263f;
            margin-top: 10px;

            .loop-shipping {
              position: absolute;
              right: 50px;
              bottom: 15px;
            }

            .loop-date {
              position: absolute;
              top: -10px;
              left: calc(50% - 16px);
              font-size: 14px;
            }
          }

          .loop-one {
            font-size: 21px;
          }

          .loop-space {
            width: 150px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            cursor: pointer;
          }

          .loop-serren {
            margin-top: 10px;
          }
        }

        .loop-min-bottom {
          width: 100%;
          height: 50px;
          border-top: 1px solid #d8d8d8;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 16px;
          box-sizing: border-box;
          font-size: 18px;
          font-weight: 400;
        }
      }
    }
  }
}

.active-tab {
  color: #91BE42 !important;
  background: #FFFFFF;
  // border-radius: 5px;
  // box-shadow: 4px -4px 4px 0px rgba(80,125,0,0.3300);
  box-shadow: 0px 3px 7px 1px rgba(80, 125, 0, 0.3300);
  border-radius: 10px 10px 0px 0px;
}

.split {
  width: 2px;
  height: 14px;
  display: inline-block;
  margin-left: 12px;
  margin-top: -2px;
  vertical-align: middle;
  background-color: #999999;
}

.mohu {
  color: transparent !important;
  -moz-user-select: none; //火狐
  -webkit-user-select: none; //webkit浏览器（Chrome，Opera，Safari等）
  -ms-user-select: none; //IE10
  -khtml-user-select: none; //早期浏览器
  user-select: none;
  text-shadow: #3c5058 0 0 7px;
}
</style>