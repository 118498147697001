<template>
  <div class="contact-maxbox">
    <div class="contact-minbox">
      <div class="minbox-right">
        <div class="title">
          <div class="title-max">联系我们 <span class="title-min">Contact Us</span></div>
        </div>
        <div class="address">
          地址：上海市杨浦区荆州路168号安联大厦509室
        </div>
        <div class="contacts">
          <div class="lable">联系人：</div>
          <div>
            <div><span class="name">李<span class="booth"></span>浩</span>（客户经理）  <span class="phone">电话：13524534404</span> 邮箱：lihao@ymlogistics.net</div>
            <div><span class="name">陈耀红</span>（客户经理） <span class="phone">电话：18621310071</span>  邮箱：chenyaohong@ymlogistics.net</div>
          </div>
        </div>
      </div>
      <div class="minbox-left">
        <mapPage :longitude="longitude" :dimension="dimension"></mapPage>
      </div>
    </div>
  </div>
</template>

<script>
import mapPage from "~/baseComponents/homeMap";
export default {
  name: "newContact",
  components:{
    mapPage
  },
  data() {
    return{
      longitude: 121.522473,
      dimension: 31.264108,
    }
  },
  mounted() {
  },
  methods:{

  }
}
</script>

<style lang="less" scoped>
.contact-maxbox{
  width: 100%;
  padding: 20px 0 70px;
  box-sizing: border-box;
  .contact-minbox{
    width: 1300px;
    margin: 40px auto 0;
    display: flex;
    justify-content: space-between;
    .minbox-left{
      width: 550px;
      height: 228px;
    }
    .minbox-right{
      display: flex;
      flex-direction: column;
      line-height: 40px;
      .contacts{
        display: flex;
        .name{
          display: inline-block;
          width: 44px;
          
          .booth{
            display: inline-block;
            width: 14px;
          }
        }
        .phone{
          margin:0 40px;
        }
      }
    }
  }
}
.title{
  margin-bottom: 20px;
  .title-max {
    font-size: 26px;
    color: #91BE42;
  }
  .title-min {
    font-size: 20px;
    color: #DFDFDF;
  }
}
</style>