<template>
  <div class="banner-maxbox">
    <el-carousel :height="screenW * (650/1920) + 'px'"  :interval="5000" :autoplay="true">
      <el-carousel-item v-for="(item,index) in bannerList" :key="index">
        <img
          :src="item.url"
          :alt="item.title"
          @click="gotoDetail(item.link_url)"
          :class="item.link_url ? 'cursor':''"
          srcset=""
        />
      </el-carousel-item>
      <!-- <el-carousel-item>
        <img
          src="https://ym-attached-file.oss-cn-shanghai.aliyuncs.com/files/banner/pc-banner2.jpg"
          alt=""
          srcset=""
        />
      </el-carousel-item> -->
    </el-carousel>
  </div>
</template>

<script>
export default {
  name: "newBanner",
  data(){
    return{
      bannerList: [],
      screenW: window.screen.width
    }
  },
  mounted(){
    this.getList();
  },
  methods:{
    async getList(){
      let res = await this.$store.dispatch("API_freight/getBannerList", {
        nopage: 1,
        source: 1 //请求来源：1.pc ; 2.移动端
      });
      if(res.success){
        this.bannerList = res.data.data;
      }
    },
    gotoDetail(href){
      if(href){
        window.open(href)
      }
    }
  }
};
</script>

<style scoped lang="less">
.el-carousel__item img {
  height: 100%;
  width: 100%;  
}
.img-box {
  width: 100%;
  height: 650px;
  border: none;
  background: url("https://ym-attached-file.oss-cn-shanghai.aliyuncs.com/pcProject/banner.png")
    no-repeat;
  background-size: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  .btn-bg {
    width: 271px;
    height: 44px;
    background: #ffffff;
    color: #3ebe0f;
    font-size: 24px;
    text-align: center;
    line-height: 44px;
    border-radius: 22px;
    margin-left: 315px;
    margin-bottom: 25px;
  }
  .btn-size {
    font-size: 48px;
    color: #ffffff;
    margin-left: 315px;
  }
}
</style>