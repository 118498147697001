<template>
  <div class="info-maxbox">
    <div class="title-maxbox">
      <div class="title">
        <div class="title-max">运抵达资讯 <span class="title-min">YunDiDa Information</span></div>
      </div>
      <div class="title-info"  @click="toInfos">
        更多资讯 >
      </div>
    </div>
    <div class="loop">
      <div class="loop-maxbox" >
        <div class="loop-minbox" v-for="(item1, index) in dataList" :key="index" @click="toDetail(item1.id)">
          <img :src="item1.img_url ? item1.img_url : 'https://ym-attached-file.oss-cn-shanghai.aliyuncs.com/pcProject/banner.png'">
          <div>
            <div class="title-top">{{item1.title}}</div>
            <div class="title-bottom">{{item1.created_time | secondFormat('LL')}} </div>
          </div>
        </div>
      </div>
      <!-- <el-carousel :interval="3000" arrow="never" :autoplay='false' indicator-position="none">
        <el-carousel-item v-for="(item, index) in dataList" :key="index">
          <div class="loop-maxbox">
            <div class="loop-minbox" v-for="(item1, index1) in dataList[index] " :key="index1" @click="toDetail(item1.id)">
              <img :src="item1.img_url ? item1.img_url : 'https://ym-attached-file.oss-cn-shanghai.aliyuncs.com/pcProject/banner.png'">
              <div>
                <div class="title-top">{{item1.title}}</div>
                <div class="title-bottom">{{item1.created_time | secondFormat('LL')}} </div>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "newInfo",
  data() {
    return{
      dataList: []
    }
  },
  mounted() {
    this.postList();
  },
  methods:{
    //运抵达资讯接口
    async postList() {
      let data = await this.$store.dispatch("API_freight/postList", {
        start: 0,
        limit: 6
      });
      if(data.success){
        this.dataList = data.data;
      }
    },
    toInfos () {
      console.log(123);
      this.$router.push({
        path: '/infoList'
      })
    },
    toDetail (id) {
      setTimeout(()=>{
        this.$log({
          type: 1,
          topic: "ymp.home",
          id: "ymp.home.information",
          content: JSON.stringify({
            id: id || '',
          }),
        });
      },1000)
      this.$router.push({
        path:'/infoDetail',
        query:{
          id: id
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.el-carousel__button{
  background-color: #97C641;
}
/deep/ .el-carousel__container{
  height: 150px;
}
.info-maxbox{
  width: 100%;
  height: 470px;
  .title-maxbox{
    width: 1300px;
    margin-top: 48px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title-info{
      font-size: 16px;
      font-weight: 400;
      color: #91BE42;
      cursor: pointer;
    }
  }
  .title{
    .title-max {
      font-size: 26px;
      color: #91BE42;
    }
    .title-min {
      font-size: 20px;
      color: #DFDFDF;
    }
  }
  .loop {
    width: 1300px;
    height: auto;
    margin: 0 auto;
    margin-top: 30px;
    .loop-maxbox{
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: wrap;
      .loop-minbox{
        width: 600px;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        cursor: pointer;
        img{
          width: 156px;
          height: 97px;
          margin-right: 16px;
        }
        .title-top{
          width: 400px;
          height: 40px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2 ;
          overflow: hidden;
          font-size: 16px;
          color: #1A1B1C;
          margin-bottom: 10px;
        }
        .title-bottom{
    
          font-size: 14px!important;
          color: #999;
          margin-top: 20px;
          text-align: left;
          box-sizing: border-box;
        }
      }
    }
  }
}
</style>