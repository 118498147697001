<template>
  <div class="ocean">
    <!-- <div class="ocan-title">
      <div class="title">
        <div class="title-max">海运服务航线</div>
        <div class="title-min">Marine Service Route</div>
      </div>
      <div class="ocan-subtitle">
        —航线覆盖全球主要港口，目前开通了日本，韩国，台湾，新加坡，澳洲，东南亚，欧洲，北美等十余条航线。
      </div>
    </div> -->
   <div class="img">
     <img src="https://ym-attached-file.oss-cn-shanghai.aliyuncs.com/pcProject/marineServiceRoute.png">
   </div>
  </div>
</template>

<script>
export default {
  name: "newOcean",
  data() {
    return{

    }
  },
  mounted() {
  },
  methods:{

  }
}
</script>

<style lang="less" scoped>
.ocean{
  width: 100%;
  height: 600px;
  margin-top: 40px;
  .ocan-title{
    width: 1300px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    .title{
      .title-max{
        font-size: 36px;
        color: #FFFFFF;
      }
      .title-min{
        font-size: 21px;
        color: #91BE42;
      }
    }
    .ocan-subtitle{
      font-size: 18px;
      font-weight: 400;
      color: #FFFFFF;
      margin-left: 21px;
    }
  }
  .img{
    width: 100%;
    height: 594px;
    overflow: hidden;
    margin: 0 auto;
    text-align: center;
    img{
      max-width: 1920px;
    }
  }
}

</style>